import {
    VENDOR_ORDERS,
    VENDOR_ORDERS_ITEMS,
    VENDOR_ORDERS_SHOW,
    VENDOR_ORDERS_TRANSITIONS,
} from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(VENDOR_ORDERS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(order = null) {
        if (order === null) return;

        const url = VENDOR_ORDERS_SHOW.replace(":order", order);

        return api.get(url);
    },

    update(order, data) {
        if (order === null) return;

        const url = VENDOR_ORDERS_SHOW.replace(":order", order);

        return api.put(url, data);
    },

    itemsIndex(order, query) {
        if (order === null) return;

        let url = new URL(VENDOR_ORDERS_ITEMS.replace(":order", order));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    transitionsIndex(order, query) {
        if (order === null) return;

        let url = new URL(VENDOR_ORDERS_TRANSITIONS.replace(":order", order));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

}