<template>
  <app-layout>
    <page-header> Comenzi </page-header>

    <page-section>
      <form class="flex" @submit.prevent="index">
        <div class="sm:w-40 mr-2 ml-0">
          <v-label for="email">E-mail</v-label>
          <v-input
            type="text"
            v-model="query.email"
            placeholder="ion@example.com"
          />
        </div>
        <div class="sm:w-40 mr-2">
          <v-label for="first_name">Prenume </v-label>
          <v-input type="text" v-model="query.first_name" placeholder="Ion" />
        </div>
        <div class="sm:w-40 mr-2">
          <v-label for="last_name">Nume de familie </v-label>
          <v-input
            type="text"
            v-model="query.last_name"
            placeholder="Popescu"
          />
        </div>
        <button class="btn self-end">Filtreaza</button>
      </form>

      <v-card class="mt-2">
        <loader v-if="loading" class="my-5" />

        <template v-else>
          <template v-if="totalRows">
            <orders-index-table :rows="rows" />
            <pagination
              @change:page="index($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>
          <no-results v-else class="m-5" />
        </template>
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import OrdersIndexTable from "../components/OrdersIndexTable.vue";
import AppLayout from "../layout/AppLayout.vue";
import ordersApi from "../api/resources/ordersApi";
import Pagination from "../components/Pagination.vue";
import VCard from "../components/VCard.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";
import VInput from "../components/VInput.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";
import VLabel from '../components/VLabel.vue';

export default {
  components: {
    AppLayout,
    OrdersIndexTable,
    Pagination,
    VCard,
    Loader,
    NoResults,
    VInput,
    PageHeader,
    PageSection,
    VLabel,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: false,
      query: {
        email: "",
        first_name: "",
        last_name: "",
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = 1) {
      this.loading = true;
      ordersApi
        .index({ page, ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = true;
        });
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
