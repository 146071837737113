<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th> # </v-table-th>
        <v-table-th>Data</v-table-th>
        <v-table-th>Client </v-table-th>
        <v-table-th>Email </v-table-th>
        <v-table-th>Total</v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.id }}
        </v-table-td>
        <v-table-td>
          {{ formatDate(row.created_at) }}
        </v-table-td>
        <v-table-td>
          {{ row.billing_address?.first_name }},
          {{ row.billing_address?.last_name }}
        </v-table-td>
        <v-table-td>
          {{ row.order?.email }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.total) }}
        </v-table-td>
        <v-table-td>
          <span
            class="
              px-2
              inline-flex
              text-xs
              leading-5
              font-semibold
              rounded-full
              bg-green-100
              text-green-800
            "
          >
            {{ row.status.label.toUpperCase() }}
          </span>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            <router-link
              :to="{
                name: 'orders.show',
                params: { id: row.id },
              }"
              class="link"
            >
              Vizualizeaza
            </router-link>
          </p>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./VTable.vue";
import VTableTd from "./VTableTd.vue";
import VTableTh from "./VTableTh.vue";
import VTbody from "./VTbody.vue";
import VThead from "./VThead.vue";
import { priceFormatter } from "@/helpers";

export default {
  components: { VTableTd, VTableTh, VTbody, VThead, VTable },
  props: {
    rows: { type: Array },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>

<style></style>
